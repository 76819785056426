@import "@/assets/scss/style.scss";

.wrapperProjectView {
    background-color: $white-two;
    min-height: 100vh;
    padding-bottom: 32px;

    @media (max-width: 1320px) {
        padding-bottom: 220px;
    }
}
